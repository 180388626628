<template>
  <div>
    <LoginForm />
    <ForgotPasswordModal />
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import LoginForm from '@/components/forms/LoginForm.vue';
import ForgotPasswordModal from '@/components/overlays/modals/ForgotPasswordModal.vue';

export default {
  components: {
    LoginForm,
    ForgotPasswordModal,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch('initialize');
    });
  },
};
</script>
