<template>
  <div>
    <Form
      class="space-y-6"
      :validation-schema="validationSchema"
      dispatch="user/login"
    >
      <Field name="email" placeholder="Email" label="Email" />
      <Field
        type="password"
        name="password"
        placeholder="Password"
        label="Password"
      />
      <div class="flex justify-between items-center">
        <Button>Sign In</Button>
        <a
          href="#"
          @click.prevent="openForgotPasswordModal"
          class="font-semibold text-sm text-blue-500"
        >
          Forgot password?
        </a>
      </div>
    </Form>
  </div>
</template>

<script>
import { object, string } from 'yup';
import { useModal } from '@/helpers/composables';

export default {
  setup() {
    const { open } = useModal('forgot-password');

    function openForgotPasswordModal() {
      open();
    }

    const validationSchema = object({
      email: string().required().email(),
      password: string().required(),
    });

    return {
      openForgotPasswordModal,
      validationSchema,
    };
  },
};
</script>
