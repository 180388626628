<template>
  <Modal id="forgot-password" :title="title">
    <template #default="{ close }">
      <p v-if="success" class="font-medium text-center">
        We sent instructions for changing your password in your email.
      </p>
      <div v-else>
        <p class="font-medium">
          Please type in the account email address to reset your password
        </p>
        <Form
          class="mt-4 space-y-6"
          :validationSchema="validationSchema"
          dispatch="user/forgotPassword"
          @success="handleSuccess"
        >
          <Field name="email" placeholder="Email" label="Email" />
          <div class="text-center">
            <Button class="mr-2 w-1/3">Send Email</Button>
            <Button class="ml-2 w-1/3" variant="secondary" @click="close"
              >Cancel</Button
            >
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script>
import { computed } from 'vue';
import { object, string } from 'yup';
import { useState } from '@/helpers/composables';

export default {
  setup() {
    const [success, setSuccess] = useState(false);

    const title = computed(() =>
      success.value ? 'Please check your email' : 'Forgot password?'
    );

    function handleSuccess() {
      setSuccess(true);
    }

    const validationSchema = object({
      email: string().required().email(),
    });

    return {
      validationSchema,
      success,
      handleSuccess,
      title,
    };
  },
};
</script>
